<template>
  <div>
    <a-modal
      :visible="modalVisible"
      :confirm-loading="confirmLoading"
      @ok="handleOkManageKBM"
      @cancel="modalVisible = false"
      :maskStyle="{
        opacity: 0.5,
        backgroundColor: 'black',
      }"
      :okText="status.isEdit ? 'Update' : 'Ok'"
      :okButtonProps="{
        props: {
          size: 'large',
        },
      }"
      :cancelButtonProps="{
        props: {
          size: 'large',
        },
      }"
    >
      <h3 slot="title" style="color: #faad14">
        <a-icon style="font-size: 1.2em" type="warning" />
        {{ status.isEdit ? "EDIT" : "GENERATE" }} KBM CONFIRMATION
      </h3>
      <p v-if="status.isEdit">
        Are you sure you want to update the kbm? Your old data will be replaced
        once you update.
      </p>
      <template v-else>
        <p>Are you sure you want to generate the kbm for this academic year?</p>
        <!-- <small
          >*KBM will be set in default at <strong>75</strong>. You can change it
          later.</small
        > -->
      </template>
    </a-modal>
    <div>
      <div class="d-md-flex align-items center">
        <div class="flex-column flex-md-row mb-4">
          <a-select
            :class="isMobile ? 'w-100' : null"
            size="large"
            placeholder="Select Type"
            @change="fetchDataCurriculum"
            v-model="selectedYear"
          >
            <a-select-option
              v-for="item in listTahunAjaran"
              :key="item.id"
              :value="item.id"
              >{{ `${item.semester}, ${item.tahun}` }}
            </a-select-option>
          </a-select>
        </div>
        <div :class="!isMobile ? 'ml-3' : null" class="mb-4">
          <a-input-group compact>
            <a-select
              :class="!isMobile ? null : filter.by == '' ? 'w-100' : 'w-50'"
              @change="changeFilterBy"
              style="min-width: 125px"
              size="large"
              v-model="filter.by"
            >
              <a-select-option value="">All Data</a-select-option>
              <a-select-option value="id_kelompok">Group</a-select-option>
              <a-select-option value="id_program">Program</a-select-option>
              <a-select-option value="id_level">Level</a-select-option>
            </a-select>
            <a-select
              :class="isMobile ? 'w-50' : null"
              @change="fetchDataCurriculum"
              :style="{
                'min-width': isMobile ? '50px' : '150px',
              }"
              size="large"
              v-if="filter.by !== ''"
              v-model="filter.value"
            >
              <a-select-option
                v-for="val in filtersData"
                :key="val.data"
                :value="val.data"
                >{{ val.name }}</a-select-option
              >
            </a-select>
          </a-input-group>
        </div>
        <div class="ml-auto" :class="isMobile ? 'mb-4' : null">
          <template v-if="!dataTable.length">
            <a-button
              :block="isMobile"
              @click.prevent="toggleManageKBM('add')"
              size="large"
              type="primary"
              icon="plus"
              >Generate KBM
            </a-button>
          </template>
          <template v-else>
            <template v-if="!status.isEdit">
              <a-button
                :block="isMobile"
                @click.prevent="toggleManageKBM('edit')"
                size="large"
                type="primary"
                icon="edit"
                >Edit KBM
              </a-button>
            </template>
            <template v-else>
              <a-button
                :block="isMobile"
                @click.prevent="toggleManageKBM"
                :loading="loadingCancel"
                type="danger"
                size="large"
                icon="close"
                class="mb-3 mb-lg-0 mr-0 mr-md-3"
                >CANCEL</a-button
              >
              <a-button
                :block="isMobile"
                @click.prevent="toggleManageKBM('save')"
                size="large"
                icon="save"
                style="background-color: #41b883; color: white"
                >SAVE</a-button
              >
            </template>
          </template>
        </div>
      </div>
      <a-row type="flex" align="middle" justify="center">
        <a-col :span="24">
          <a-table
            class="hasan-table-overflow"
            :columns="columnCurriculums"
            :data-source="dataTable"
            :loading="loadingTable"
            bordered
            :pagination="{ pageSize: 30, hideOnSinglePage: true }"
          >
            <!-- <div v-if="status.isEdit" slot="isReport" slot-scope="text, record">
              <a-checkbox :checked="text" @change="(e) => handleChange(e.target.checked, record.key, 'isReport')" />
            </div>
            <div v-else slot="isReport" slot-scope="text">
              <a-checkbox :checked="text" disabled />
            </div> -->
            <!-- <div slot="isReport" slot-scope="text">
              <a-checkbox :checked="text" disabled />
            </div> -->
            <!-- <div v-if="status.isEdit" slot="reportOrder" slot-scope="text, record">
              <a-input-number
                :min="1"
                :max="100"
                :value="text"
                style="height: 48px; width: 80px"
                @change="(e) => handleChange(Number(e), record.key, 'reportOrder')"
              />
            </div>
            <div v-else slot="reportOrder" slot-scope="text">
              {{ text }}
            </div> -->
            <!-- <div slot="reportOrder" slot-scope="text">
              {{ text || '-' }}
            </div> -->
            <div v-if="status.isEdit" slot="nilaiKbm" slot-scope="text, record">
              <a-select
                size="large"
                :default-value="record.idKbmMaster"
                class="w-100"
                @change="(e) => handleChange(e, record.key, 'idKbmMaster')"
              >
                <a-select-option
                  v-for="kbm in listKbm"
                  :key="kbm.id"
                  :value="kbm.id"
                >
                  {{ kbm.nilai }}
                </a-select-option>
              </a-select>
              <!-- <a-input-number
                :min="0"
                :max="100"
                :value="Number(text)"
                style="height: 48px; width: 80px"
                @change="(e) => handleChange(Number(e), record.key, 'idKbmMaster')"
              /> -->
            </div>
            <div v-else slot="nilaiKbm" slot-scope="text">
              {{ text || "-" }}
            </div>
          </a-table>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
const columnCurriculums = [
  // {
  //   title: 'Report Order',
  //   dataIndex: 'reportOrder',
  //   width: 100,
  //   align: 'center',
  //   scopedSlots: { customRender: 'reportOrder' },
  // },
  {
    title: 'Subject',
    dataIndex: 'subject',
    // width: 200,
    align: 'left',
    scopedSlots: { customRender: 'subject' },
  },
  {
    title: 'Code',
    dataIndex: 'code',
    // width: 100,
    align: 'center',
    scopedSlots: { customRender: 'code' },
  },
  // {
  //   title: 'Include on Report',
  //   dataIndex: 'isReport',
  //   width: 100,
  //   align: 'center',
  //   scopedSlots: { customRender: 'isReport' },
  // },
  {
    title: 'KBM Score',
    dataIndex: 'nilaiKbm',
    // width: 100,
    align: 'center',
    scopedSlots: { customRender: 'nilaiKbm' },
  },
]

export default {
  data() {
    return {
      columnCurriculums,
      selectedYear: null,
      modalVisible: false,
      confirmLoading: false,
      loadingCancel: false,
      savedData: [],
      dataTable: [],
      listTahunAjaran: [],
      activeYear: {},
      status: {
        isCreate: false,
        isEdit: false,
      },
      listProgram: [],
      listGroup: [],
      listLevel: [],
      listKbm: [],
      filter: {
        by: '',
        value: null,
      },
      loadingTable: false,
    }
  },
  methods: {
    handleSelect(value) {
      this.selectedYear = Number(value)
      this.fetchDataCurriculum()
    },
    async fetchDataTahunAjaran() {
      try {
        const res = await this.$store.dispatch('curriculum/FETCH_TAHUN_AJARAN')
        this.activeYear = res.activeYear
        this.listTahunAjaran = res.data
        this.selectedYear = res.activeYear.id
        return new Promise((resolve) => resolve())
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async fetchDataCurriculum() {
      try {
        this.loadingTable = true
        // console.log(this.selectedYear, 'masul')
        const filter = this.filter.by !== '' ? {
          filterBy: this.filter.by,
          filterValue: this.filter.value,
        } : {}
        const res = await this.$store.dispatch('curriculum/FETCH_CURRICULUM_ALL_MAPEL_KBM', { selectedYear: this.selectedYear, ...filter })
        // console.log('fetchDataCurriculum res =>', res)
        this.loadingCancel = false
        this.loadingTable = false
        let no = 1
        const data = res.data.sort((a, b) => a.reportOrder - b.reportOrder)
        this.dataTable = data.map((row) => {
          return {
            key: row.idMapel,
            no: no++,
            subject: row.nama,
            code: row.code,
            isReport: row.isReport,
            reportOrder: row.reportOrder,
            idKbm: row.idKbm,
            nilaiKbm: row.nilaiKbm,
            idKbmMaster: row.idKbmMaster,
          }
        })
        // console.log(this.dataTable)
        this.savedData = this.dataTable
        return new Promise((resolve, reject) => resolve())
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    validateDataInput(data) {
      // console.log('validateDataInput', data)
      let isInvalid = false
      let message = ''
      for (let i = 0; i < data.length; i++) {
        // const { reportOrder: reportOrder1, idKbm: idKbm1 } = data[i]
        if (data[i].nilaiKbm === '-') {
          data[i].nilaiKbm = null
        }
        if (isNaN(Number(data[i].nilaiKbm))) {
          isInvalid = true
          message = `Sorry, ${data[i].subject}'s KBM must be numbers.`
          break
        } else if (Number(data[i].nilaiKbm) > 100 || Number(data[i].nilaiKbm) < 0) {
          isInvalid = true
          message = `Sorry, ${data[i].subject}'s KBM must be between 0 and 100.`
          break
        }

        // no longer need report order validation

        // for (let j = 0; j < data.length; j++) {
        //   const { reportOrder: reportOrder2, idKbm: idKbm2 } = data[j]
        //   if (idKbm2 !== idKbm1 && reportOrder1 === reportOrder2) {
        //     isInvalid = true
        //     message = `Sorry, ${data[i].subject} and ${data[j].subject} have same report order. Can't update KBM`
        //     break
        //   }
        // }

        if (isInvalid) break
      }
      return {
        isInvalid,
        message,
      }
    },
    async handleOkManageKBM() {
      try {
        this.confirmLoading = true
        if (this.status.isEdit) {
          const newData = [...this.savedData]
          // const validation = this.validateDataInput(newData)
          // if (validation.isInvalid) {
          //   this.$notification.error({
          //     message: 'Sorry.',
          //     description: validation.message,
          //   })
          //   this.modalVisible = false
          //   this.confirmLoading = false
          //   // this.toggleManageKBM()
          // } else {
          const res = await this.$store.dispatch('curriculum/UPDATE_KBM', {
            data: newData,
          })
          setTimeout(() => {
            this.fetchDataCurriculum()
            this.toggleManageKBM()
            this.confirmLoading = false
            if (res.isSuccess) {
              this.$notification.success({
                message: 'Success',
                description: 'KBM has been updated',
              })
            } else {
              this.$notification.error({
                message: 'Sorry',
                description: 'KBM has not been updated',
              })
            }
          }, 1000)
          // }
        } else {
          const id = this.selectedYear
          const res = await this.$store.dispatch('curriculum/GENERATE_KBM', { id })
          setTimeout(() => {
            this.fetchDataCurriculum()
            this.toggleManageKBM()
            this.confirmLoading = false
            if (res.isSuccess) {
              this.$notification.success({
                message: 'Success',
                description: 'KBM has been generated',
              })
            } else {
              this.$notification.error({
                message: 'Sorry',
                description: 'KBM has not been generated',
              })
            }
          }, 1000)
        }
      } catch (err) {
        this.confirmLoading = false
        const status = this.status.isEdit ? 'updated' : 'generated'
        this.$notification.error({
          message: 'Sorry',
          description: 'KBM has not been ' + status,
        })
        console.log(err)
      }
    },
    async toggleManageKBM(status) {
      if (status === 'add') {
        this.status.isCreate = true
        this.modalVisible = true
      } else if (status === 'edit') {
        this.status.isEdit = true
      } else if (status === 'save') {
        this.modalVisible = true
      } else {
        await this.fetchDataCurriculum()
        this.modalVisible = false
        this.status = {
          isCreate: false,
          isEdit: false,
        }
      }
    },
    handleChange(value, key, column) {
      // console.log('handleChange =>', value, key, column)
      const newData = [...this.dataTable]
      const target = newData.filter((item) => key === item.key)[0]
      if (target) {
        target[column] = value
        this.savedData = newData
      }
    },
    async fetchKbm() {
      try {
        const data = await this.$store.dispatch('master/FETCH_PASSING_GRADE', { page: 'all' })
        console.log(data)
        this.listKbm = data
      } catch (error) {
        console.log('error kbm', error)
        return this.$notification.error({
          message: 'Error',
          description: 'The process of fetching kbm encountered problems. Please try again later',
        })
      }
    },
    async fetchLevels() {
      try {
        const data = await this.$store.dispatch('master/FETCH_LEVEL', { page: 'all' })
        this.listLevel = data.map(level => {
          return {
            nama: level.nama,
            id: level.id,
          }
        })
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async fetchGroups() {
      try {
        const { data } = await this.$store.dispatch('master/FETCH_GROUP', { page: 'all' })
        this.listGroup = data.map(group => {
          return {
            nama: group.nama,
            id: group.id,
          }
        })
        this.listGroup = this.listGroup.sort((a, b) => a.id - b.id)
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async fetchProgram() {
      try {
        const data = await this.$store.dispatch('master/FETCH_PROGRAM', { page: 'all' })
        this.listProgram = data.map(program => {
          return {
            nama: program.nama,
            id: program.id,
          }
        })
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    changeFilterBy() {
      if (this.filter.by === '') this.fetchDataCurriculum()
      this.filter.value = null
    },
  },
  async mounted() {
    try {
      this.fetchLevels()
      this.fetchGroups()
      this.fetchProgram()
      await this.fetchDataTahunAjaran()
      await this.fetchKbm()
      this.fetchDataCurriculum()
    } catch (err) {
      console.log(err)
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
    filtersData() {
      let datas = []
      if (this.filter.by === 'id_level') {
        datas = this.listLevel.map(level => {
          return {
            data: level.id,
            name: level.nama,
          }
        })
      } else if (this.filter.by === 'id_kelompok') {
        datas = this.listGroup.map(group => {
          return {
            data: group.id,
            name: group.nama,
          }
        })
      } else if (this.filter.by === 'id_program') {
        datas = this.listProgram.map(program => {
          return {
            data: program.id,
            name: program.nama,
          }
        })
      }
      return datas
    },
  },
}
</script>
